import React from 'react';
import './ResultTablePrint.scss';
import { IResultTableProps } from './types';

import logo from '../../../assets/images/RentometerLogo.png';

const ResultTablePrint = React.forwardRef<HTMLInputElement, IResultTableProps>((props, ref) => {
  const { reportData } = props;
  const formatNumber = (number) => number?.toLocaleString().split(/\s/).join(',');
  const formatAMPM = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  };
  const generateTimestamp = () => {
    let date = new Date();
    return `${
      (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
      '/' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      '/' +
      date.getFullYear() +
      ', ' +
      formatAMPM(date)
    }`;
  };

  const singleReport = reportData?.data?.map((item, i) => {
    return (
      <div className={`table_item ${(i + 1) % 2 === 0 ? '' : 'table_item-gray'}`} key={i}>
        <div className="number">{i + 1}</div>
        <div className="address">{typeof item.address === 'string' && item.address}</div>
        <div className="bed">{item.isComplete ? item.bedrooms : '-'}</div>
        <div className="bath">{item.isComplete ? item.bathrooms : '-'}</div>
        {/*<div className="rent">*/}
        {/*  {item.isComplete && item.currentRent ? `$${formatNumber(item.currentRent)}` : '-'}*/}
        {/*</div>*/}
        <div className="rent">{item.isComplete ? `$${formatNumber(item.mean)}` : '-'}</div>
      </div>
    );
  });

  const multiReport = reportData?.data?.map((item, i) => {
    return (
      <div className={`table_item ${(i + 1) % 2 === 0 ? '' : 'table_item-gray'}`} key={i}>
        <div className="multi_number">{i + 1}</div>
        <div className="multi_address">{item.description}</div>
        <div className="multi_bed">{item.isComplete ? item.bedrooms : '-'}</div>
        <div className="multi_bath">{item.isComplete ? item.bathrooms : '-'}</div>
        <div className="multi_rent">{item.isComplete ? `$${formatNumber(item.mean)}` : '-'}</div>
        <div className="multi_count__value">{item.isComplete ? item.count : '-'}</div>
        <div className="multi_total_rent">${formatNumber(item.multiTotalRent)}</div>
      </div>
    );
  });

  const singleReportResult = () => {
    return (
      <>
        <div className="main">
          <div className="main_table">
            <div className="table_lables">
              <div className="number">№</div>
              <div className="address">Address</div>
              <div className="bed">BR</div>
              <div className="bath">BA</div>
              {/*<div className="rent">Current Rent</div>*/}
              <div className="rent">Average Rent</div>
            </div>
            {singleReport}
          </div>
        </div>
        <div className="total">
          <div className="total_table">
            <div className="table_lables">
              <div className="number">Total</div>
              <div className="address"></div>
              <div className="bed"></div>
              <div className="bath"></div>
              {/*<div className="rent"></div>*/}
              <div className="rent"></div>
            </div>
            <div className="total_item">
              <div className="number">{reportData?.data?.length}</div>
              <div className="address"></div>
              <div className="bed">{reportData?.totalBedrooms}</div>
              <div className="bath"></div>
              {/*<div className="rent">${formatNumber(reportData?.totalCurrentRent)}</div>*/}
              <div className="rent">${formatNumber(reportData?.totalAvarageRent)}</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const multiReportResult = () => {
    return (
      <>
        <div className="multi_title">
          <strong>Address: </strong>
          {reportData?.data &&
            typeof reportData.data[0].address === 'string' &&
            reportData.data[0].address}
        </div>
        {reportData?.data?.some((element) => element.isComplete === false) ? (
          <div className="noData_message">There is no data for the address currently</div>
        ) : (
          <>
            <div className="main">
              <div className="main_table">
                <div className="table_lables">
                  <div className="multi_number">№</div>
                  <div className="multi_address">Unit Description</div>
                  <div className="multi_bed">BR</div>
                  <div className="multi_bath">BA</div>
                  <div className="multi_rent">Average Rent</div>
                  <div className="multi_count">Number of Units</div>
                  <div className="multi_total_rent">Total Rent</div>
                </div>
                {multiReport}
              </div>
            </div>
            <div className="total">
              <div className="total_table">
                <div className="table_lables">
                  <div className="multi_number">Total</div>
                  <div className="multi_address"></div>
                  <div className="multi_bed"></div>
                  <div className="multi_bath"></div>
                  <div className="multi_rent"></div>
                  <div className="multi_count"></div>
                  <div className="multi_total_rent"></div>
                </div>
                <div className="total_item">
                  <div className="multi_number">{reportData?.data?.length}</div>
                  <div className="multi_address"></div>
                  <div className="multi_bed">{reportData?.totalBedrooms}</div>
                  <div className="multi_bath"></div>
                  <div className="multi_rent">${formatNumber(reportData?.totalAvarageRent)}</div>
                  <div className="multi_count__value">{formatNumber(reportData?.totalCount)}</div>
                  <div className="multi_total_rent">${formatNumber(reportData?.totalRent)}</div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  const single = singleReportResult();
  const multi = multiReportResult();

  return (
    <div ref={ref} className="_printContainer">
      <div className="header">
        <div className="headerLogo">
          <img src={logo} />
          <div className="headerLogoText">{generateTimestamp()}</div>
        </div>
        <div className="header_text">
          <div className="header_title">GPR Calculator</div>
          <div className="header_subtitle">
            {reportData?.reportType === 'single' ? 'Single-Family Houses' : 'Multi-Family Building'}
          </div>
        </div>
      </div>
      <div className="aside">
        <div className="aside_item">
          <div className="aside_value">${formatNumber(reportData?.monthGPR)}</div>
          <div className="aside_title">Monthly Gross Potential Rent (GPR)</div>
        </div>
        <div className="aside_item">
          <div className="aside_value">${formatNumber(reportData?.estimatedPropertyValue)}</div>
          <div className="aside_title">
            Estimated {reportData?.reportType === 'single' ? 'Portfolio' : 'Property'} Value (1%
            rule)
          </div>
        </div>
        <div className="aside_item">
          <div className="aside_value">{formatNumber(reportData?.numberOfItems)}</div>
          <div className="aside_title">
            Number of {reportData?.reportType === 'single' ? 'houses' : 'units'} calculated
          </div>
        </div>
        <div className="aside_item">
          <div className="aside_value">${formatNumber(reportData?.averageRentForItem)}</div>
          <div className="aside_title">
            Average rent for one {reportData?.reportType === 'single' ? 'house' : 'unit'}
          </div>
        </div>
      </div>
      {reportData?.reportType === 'single' ? single : multi}
    </div>
  );
});

ResultTablePrint.displayName = 'ResultTablePrint';

export default ResultTablePrint;
